<template>
  <v-app>
    <v-navigation-drawer
        
        app
        :mini-variant.sync="mini"
     >
    <v-list>
      <v-list-item class="px-2" :to="{'name': 'Points'}">
        <v-list-item-content>
 <v-list-item-avatar>
            <v-icon>mdi-map-marker</v-icon>
          </v-list-item-avatar>
         
        </v-list-item-content>
         
      </v-list-item>
    </v-list>

    </v-navigation-drawer>
    <v-app-bar
      app
      
      color="primary"
      dark
    >
      <div class="d-flex align-center">
        Go-France ADMIN
      </div>

      <v-spacer></v-spacer>
<v-btn v-if="$store.getters.user.logged" dense small outlined>
  {{ $store.getters.user.logged.email }}
</v-btn>
      <v-btn
      v-if="!$store.getters.user.logged"
        @click="dialogLogin = true"
        text
      >
        <span class="mr-2">Connexion</span>
      </v-btn>
      <v-btn
      v-else
        @click="askLogout"
        text
      >
        <span class="mr-2">Déconnexion</span>
      </v-btn>
    </v-app-bar>

    <v-main>
      <router-view ></router-view>

    </v-main>
    <v-dialog
      v-model="dialogLogin"
      persistent
      max-width="500"
    >
  <v-card>
    <v-card-title><h2>Connexion</h2><v-spacer />
    <v-btn icon @click="dialogLogin = false;"><v-icon>mdi-close</v-icon></v-btn>
    
    </v-card-title>
    <v-divider />
    <v-card-text>
     <div id="firebaseui-auth-container">
     </div>
    </v-card-text>
  
  </v-card>
</v-dialog>
  </v-app>
</template>

<script>
import { getAuth, GoogleAuthProvider, FacebookAuthProvider} from "firebase/auth";
import * as firebaseui from 'firebaseui'
import 'firebaseui/dist/firebaseui.css'

//import * as auth from '@firebase/auth';

export default {
  name: 'App',

 

  data: () => ({
    ui: null,
    uiConfig: {},
    mini: true,
    auth: null,
    dialogLogin: false
  }),
  watch: {
    'dialogLogin': function(v) {
      if (v) {
        setTimeout(() => {
          this.ui.start('#firebaseui-auth-container', this.uiConfig);
        }, 500);
         
      }
    }
  },
  methods: {
    askLogout: function() {
      if(window.confirm('Etes-vous sûr de vouloir vous déconnecter ?')){
          //this.$store.dispatch('setUserLogged', false);
          this.auth.signOut().then(() => {
             alert('Déconnexion réussie');
           }).catch(() => {
             alert('Erreur lors de la déconnexion');
           })
      }
    }
  },
  mounted() {
    console.log(this.$store.watch);
    this.auth = getAuth();
    this.uiConfig = {
       callbacks: {
    signInSuccessWithAuthResult: function(authResult, redirectUrl) {
      // User successfully signed in.
      // Return type determines whether we continue the redirect automatically
      // or whether we leave that to developer to handle.
      console.log(authResult);
      console.log(redirectUrl);
      return true;
    }
  }, signInSuccessUrl: "?check_login",
        signInOptions: [
          // Leave the lines as is for the providers you want to offer your users.
            GoogleAuthProvider.PROVIDER_ID,
    FacebookAuthProvider.PROVIDER_ID,
        ],
        // tosUrl and privacyPolicyUrl accept either url string or a callback
        // function.
        // Terms of service url/callback.
        tosUrl: '<your-tos-url>',
        // Privacy policy url/callback.
        privacyPolicyUrl: function() {
          window.location.assign('<your-privacy-policy-url>');
        }
      };
      this.ui = firebaseui.auth.AuthUI.getInstance();
      if (!this.ui) {
        this.ui = new firebaseui.auth.AuthUI(this.auth);
      }
      if(this.ui.isPendingRedirect()){
        this.dialogLogin = true;
      }
    
    
    this.auth.onAuthStateChanged((user) => {
      
 this.$store.dispatch('setUserLogged', user? true: false);  
 if (user) {
   user.getIdTokenResult().then((token) => {
          //if (token.claims)
          //console.log(token.claims.admin);
          if (token.claims.admin === true) {
            this.$store.dispatch('setUserAdmin', true); 
          } else {
            this.$store.dispatch('setUserAdmin', false); 
          }
          
      })
    this.$store.dispatch('setUserData', user); 
 } else {
   this.$store.dispatch('setUserAdmin', false); 
 }
 
});
   
   
  }
};
</script>
