
import Vue from 'vue'
import App from './App.vue'
import vuetify from './plugins/vuetify'
//import { initializeApp }  from 'firebase/app';
import VueRouter from 'vue-router'


import router from './router'
import store from './store'
import { initializeApp } from 'firebase/app';
import  { getAnalytics } from 'firebase/analytics';

Vue.use(VueRouter)

if (process.env.NODE_ENV && process.env.NODE_ENV=='development') {
  var firebaseConfig = {
    apiKey: "AIzaSyDnqF8m8IVeQTmVBsQ3Iz9aNWh7L7Zdq68",
    authDomain: "gofrance-dev.firebaseapp.com",
    databaseURL: "https://gofrance-dev.firebaseio.com",
    projectId: "gofrance-dev",
    storageBucket: "gofrance-dev.appspot.com",
    messagingSenderId: "955856492053",
    appId: "1:955856492053:web:b5175d641ae61df83ae01c"
  };
  
} else {
  firebaseConfig = {
    apiKey: "AIzaSyBgW1MYSndZiWaCpgqOMiwPLyy8igcxdwk",
    authDomain: "gofrance-7eb17.firebaseapp.com",
    databaseURL: "https://gofrance-7eb17.firebaseio.com",
    projectId: "gofrance-7eb17",
    storageBucket: "gofrance-7eb17.appspot.com",
    messagingSenderId: "448361569742",
    appId: "1:448361569742:web:1251576e085db9d9"
  };
  
}


initializeApp(firebaseConfig);
getAnalytics();



Vue.config.productionTip = false

new Vue({
  vuetify,
  store,
  router,
  beforeCreate() {
    this.$store.commit('initialiseStore');
  },
  render: h => h(App)
}).$mount('#app')
