import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store';
Vue.use(VueRouter)

const routes = [
  {
    path: '/points',
    name: 'Points',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '@/components/points/list.vue'),
    beforeEnter: async (to, from, next) => {
        await store.watch((state) => { 
            return state.user.admin;
        }, () => {
            
        })
        console.log(store.getters.user.admin);
        if (store.getters.user.admin === true) {
            next();
        } else {
            next({name: '403' })
        }
        
    }
  },
  {
      path: '/403',
      name: '403',
      component: () =>  import(/* webpackChunkName: "about" */ '@/components/403.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
