import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)
const store =  new Vuex.Store({
    state: {
        user: {logged: false, data: null, admin: null}
    },
    getters: {
        user: state => state.user
    },
    mutations: {
        initialiseStore(state) {
            if(localStorage.getItem('store')) {
                    // Replace the state object with the stored item
                    this.replaceState(
                      Object.assign(state, JSON.parse(localStorage.getItem('store')))
                    );
            }
        },
        setUserLogged(state, data) {
            state.user.logged = data;
           
        },
        setUserData(state, data) {
            state.user.logged = data;
            if (!data) {
                state.user.data = null;
            }
        },
        setUserAdmin(state, data) {
           
            state.user.admin = data;
        }
    },
    actions: {
        
        setUserLogged({commit}, data){
            commit('setUserLogged', data);
        },
        setUserData({commit}, data){
            commit('setUserData', data);
        },
        setUserAdmin({commit}, data){
            commit('setUserAdmin', data);
        }
    }
})

store.subscribe((mutation, state) => {
	// Store the state object as a JSON string
	localStorage.setItem('store', JSON.stringify(state));
});
export default store
